import { useStaticQuery, graphql } from 'gatsby'
import { FilterModalElement, AlternateUrl, ParsedSku } from '../types'
import {env} from '../../environment'
import { getProductsTemplateUrl } from './utils'

let staticCache: any = undefined
let buildingStaticCache = false

export const _getDataFromGraphQL = () => {
  // Original query...
  // const data = useStaticQuery(graphql`
  //   query Slugs {
  //     allCockpitUrlslugs(filter: { lang: { eq: "de" } }) {
  //       nodes {
  //         url_slug {
  //           value
  //         }
  //         internal_key {
  //           value
  //         }
  //         lang
  //       }
  //     }
  //   }
  // `)

  // Headless CMS + SI Materials in env.lang
  const data = useStaticQuery(graphql`
    query Slugs {
      allUrlSlugs {
        nodes {
          url_slug
          internal_key
          language
        }
      }
    }
  `)

  return data
}

export const parseHashUrl = (url: string) => {
  const result: any = {}
  const keys = ['variant', 'ratio', 'text']

  let urlToParse = url
  keys.map((key: string) => {
    if (urlToParse.indexOf(key + '=') >= 0) {
      const i = urlToParse.indexOf(key + '=') + (key + '=').length
      const j = urlToParse.indexOf(',')

      if (j === -1) {
        result[key] = urlToParse.slice(i)
      } else {
        result[key] = urlToParse.slice(i, j)
        urlToParse = urlToParse.slice(j + 1)
      }
    }
  })

  if (typeof result.variant === 'string') {
    result.variant = result.variant.replace('/', '')
  }

  return result
}

export const createHashUrl = (hash: any) => {
  if (typeof hash === 'string') {
    return '#variant=' + hash
  } else {
    const url: string[] = []
    Object.entries(hash).map((ar) => {
      if (ar[1] !== 0 && ar[1] !== '') {
        url.push(ar[0] + '=' + ar[1])
      }
    })

    if (url.length >= 0) {
      return '#' + url.join(',')
    } else {
      return ''
    }
  }
}

export const initializeUrlSlugs = () => {
  buildingStaticCache = true
  staticCache = []
  _getDataFromGraphQL().allUrlSlugs.nodes.map((elem: any) => {
    staticCache.push(elem)
  })

  buildingStaticCache = false
  return staticCache
}

export const _getData = () => {
  if (staticCache === undefined && buildingStaticCache == false) {
    return initializeUrlSlugs()
  }

  return staticCache
}

export const mapSlug2InternalKey = (slug: string) => {
  const data = _getData()

  const language = env.getLanguage()
  const slugs = data.filter((elem: any) => {
    return elem.url_slug == slug && elem.language == language
  })

  if (slug === undefined) {
    return undefined
  } else if (slugs.length == 1) {
    return slugs[0].internal_key
  } else {
    console.warn(
      'Missing internal key for URL Slug "' +
        slug +
        '" (got ' +
        slugs.length +
        ')',
    )
    return undefined
  }
}

export const mapInternalProductMaterial2InternalKey = (material: string) => {
  if (
    [
      'cups',
      'cases',
      'puzzle',
      'giftvoucher',
      'pillow',
      'magnet',
      'placemat',
    ].indexOf(material) !== -1
  ) {
    return 'gifts'
  }

  if (['tshirt', 'hoodie', 'sweatshirt'].indexOf(material) !== -1) {
    return 'fashion'
  }

  if (
    [
      'wallcalendar',
      'deskcalendar',
      'foldingcalendar',
      'advent',
      'squarecalendar',
      'familyplaner',
    ].indexOf(material) !== -1
  ) {
    return 'calendar'
  } else {
    return 'wallart'
  }
}

export const mapInternalKey2Slug = (internalKey: string, language?: string) => {
  const data = _getData()

  if (!language) {
    language = env.getLanguage()
  }

  const slugs = data.filter((elem: any) => {
    return elem.internal_key == internalKey && elem.language == language
  })

  if (slugs.length == 1) {
    return slugs[0].url_slug
  } else {
    // TODO
    // console.error('Missing URL Slug for internal key "' + internalKey + '"')
    return internalKey
  }
}

export const _isMaterialAvailable = (material: string, country: string) => {
  let result = true

  if (
    country != 'de' &&
    ['tshirt', 'sweatshirt', 'hoodie'].indexOf(material) >= 0
  ) {
    result = false
  }

  if (
    ['ch', 'gb'].indexOf(country) >= 0 &&
    ['metalposter'].indexOf(material) >= 0
  ) {
    result = false
  }

  if (['ch'].indexOf(country) >= 0 && ['cups'].indexOf(material) >= 0) {
    result = false
  }

  // console.log('_isMaterialAvailable(' + material + ',' + country + "): " +  (result? "yes":"no"))

  return result
}

export const _getAlternateLocales = () => {
  const alternateLocales = [
    {
      country: 'de',
      lang: 'de',
      locale: 'de-DE',
      url: 'https://www.artboxone.de',
    },
    {
      country: 'at',
      lang: 'de',
      locale: 'de-AT',
      url: 'https://www.artboxone.at',
    },
    {
      country: 'ch',
      lang: 'de',
      locale: 'de-CH',
      url: 'https://www.artboxone.ch',
    },
    {
      country: 'gb',
      lang: 'en',
      locale: 'en-GB',
      url: 'https://www.artboxone.co.uk',
    },
    {
      country: 'dk',
      lang: 'en',
      locale: 'da-DK',
      url: 'https://www.artboxone.dk',
    },
    // { country: 'fr', lang: 'en', locale: 'en-FR', url: 'https://www.artboxone.fr' },
    {
      country: 'nl',
      lang: 'en',
      locale: 'en-NL',
      url: 'https://www.artboxone.nl',
    },
    {
      country: 'de',
      lang: 'en',
      locale: 'x-default',
      url: 'https://www.artboxone.com',
    },
  ]

  return alternateLocales
}

export const buildAlternativeUrlsForLps = (
  lp: string,
  landingPageCrossUrls: any,
) => {
  const result: AlternateUrl[] = []

  _getAlternateLocales().map((locale: any) => {
    let searchForUrl = 'de'

    if (locale.country == 'ch') {
      searchForUrl = 'ch'
    } else if (locale.country == 'dk') {
      searchForUrl = 'da'
    } else if (
      ['gb', 'dk', 'fr', 'nl'].indexOf(locale.country) >= 0 ||
      locale.locale == 'x-default'
    ) {
      searchForUrl = 'en'
    }

    if (landingPageCrossUrls[searchForUrl] != '') {
      result.push({
        lang: locale.lang,
        locale: locale.locale,
        url: locale.url + landingPageCrossUrls[searchForUrl],
      })
    }
  })

  return result
}

export const buildAlternateUrlsForPues = (
  materialName: string,
  categories: string[],
  sortingOrder: string,
  searchQuery: string,
  page?: number,
  variant?: string,
) => {
  const result: AlternateUrl[] = []

  _getAlternateLocales()
    .filter((node) => {
      return _isMaterialAvailable(materialName, node.country)
    })
    .map((locale: any) => {
      result.push({
        lang: locale.lang,
        locale: locale.locale,
        url:
          locale.url +
          getProductsTemplateUrl(
            materialName,
            categories,
            sortingOrder,
            searchQuery,
            page,
            variant,
            locale.lang,
          ),
      })
    })

  return result
}

export const buildCanonicalUrlsForPes = (
  material: string,
  productInformation: ParsedSku,
) => {
  const url = buildPesUrl(
    material,
    '',
    productInformation.motiveId,
    productInformation.slug,
  )

  const baseUrl = env.getBaseUrl()

  const result: string[] = []
  result.push(baseUrl + url)

  return result
}

export const buildAlternateUrlsForPes = (
  material: string,
  productInformation: ParsedSku,
) => {
  const result: AlternateUrl[] = []

  _getAlternateLocales()
    .filter((node) => {
      return _isMaterialAvailable(material, node.country)
    })
    .map((locale: any) => {
      result.push({
        lang: locale.lang,
        locale: locale.locale,
        url:
          locale.url +
          buildPesUrl(
            material,
            '',
            productInformation.motiveId,
            productInformation.slug,
            undefined,
            undefined,
            locale.lang,
          ),
      })
    })

  return result
}

export const buildPesUrl = (
  material: string,
  materialSlug: string,
  motiveId: number,
  motiveSlug: string,
  ratio?: number,
  enteredText?: string,
  lang?: string,
) => {
  let result =
    '/' +
    mapInternalKey2Slug(
      mapInternalProductMaterial2InternalKey(material),
      lang,
    ) +
    '/' +
    mapInternalKey2Slug(material, lang)

  result += '/' + motiveId + '-' + motiveSlug

  if (materialSlug !== '') {
    const data = {
      variant: mapVariant(materialSlug),
      ratio: 0,
      text: '',
    }

    if (typeof ratio === 'number' && ratio > 0 && ratio != 11) {
      data['ratio'] = ratio
    }

    if (typeof enteredText === 'string' && enteredText != '') {
      data['text'] = enteredText
    }

    result += createHashUrl(data)
  }

  return result
}

export const buildUpsellingUrl = (
  material: string,
  motiveId: number,
  motiveSlug: string,
  ratio?: number,
  materialSlug?: string,
) => {
  const result =
    '/upselling/' +
    mapInternalKey2Slug(material) +
    '/' +
    motiveId +
    '-' +
    motiveSlug

  if (materialSlug && materialSlug !== '') {
    const data = { variant: mapVariant(materialSlug), ratio: 0 }

    if (typeof ratio === 'number' && ratio > 0 && ratio != 11) {
      data['ratio'] = ratio
    }

    return result.concat(createHashUrl(data))
  }

  return result
}

export const mapVariant = (materialSlug: string) => {
  let result = materialSlug

  if (materialSlug == 'heart') {
    result = mapInternalKey2Slug('13543')
  } else if (materialSlug == 'emaille') {
    result = mapInternalKey2Slug('13545')
  } else if (materialSlug == 'cup') {
    result = mapInternalKey2Slug('13192')
  } else if (materialSlug == 'cup') {
    result = mapInternalKey2Slug('13192')
  } else if (materialSlug == 'plastic_small') {
    result = mapInternalKey2Slug('13542')
  } else if (materialSlug == 'plastic_big') {
    result = mapInternalKey2Slug('13544')
  }

  return result
}

export const getThumbnailReplacementType = (material: string) => {
  let replacement = 'pixumImageId'

  if (
    ['cup', 'heart', 'emaille', 'plastic_small', 'plastic_big'].indexOf(
      material,
    ) !== -1
  ) {
    replacement = 'motiveId'
  }

  if (material.indexOf('shirt_') == 0) {
    replacement = 'motiveIds'
  } else if (material.indexOf('hoodie_') == 0) {
    replacement = 'motiveIds'
  } else if (material.indexOf('sweater_') == 0) {
    replacement = 'motiveIds'
  }

  if (
    [
      'wallcalendar',
      'foldingcalendar',
      'deskcalendar',
      'familyplaner',
      'squarecalendar',
    ].indexOf(material) !== -1
  ) {
    replacement = 'motiveId'
  }

  return replacement
}

export const getSlugs = (fmes: FilterModalElement[]) => {
  const result: string[] = []

  fmes.map((fme) => {
    result.push(fme.slug)
  })

  return result
}
