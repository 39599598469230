import {env} from '../../../environment'

const fetchError = (options: any) => {
  let form = new FormData()
  form.append('data', options.data)

  let url = env.getServiceHost() + 'app/error'
  return fetch(url, { method: 'post', body: form }).then(response =>
    response.json(),
  )
}

export default fetchError
