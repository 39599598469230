import { SelectButtonOption, VatSet } from '../types'
import { getCart } from '../lib/cart_hook'
import {env} from './../../environment'
import { getVatSets, useVatSetsData } from '../lib/vatsets_hook'
import { isBrowser } from './utils'

export const getCountryOptions = (constraintCountry?: string) => {
  const options: SelectButtonOption[] = []

  if (isBrowser()) {
    getVatSets().map((vatSet: VatSet) => {
      options.push({ key: vatSet.iso.toLowerCase(), name: vatSet.name })
    })
  }

  // Fallback
  if (options.length == 0) {
    options.push({ key: 'de', name: 'Deutschland' })
    options.push({ key: 'at', name: 'Österreich' })
    options.push({ key: 'ch', name: 'Schweiz' })
    options.push({ key: 'dk', name: 'Denmark' })
    options.push({ key: 'gb', name: 'United Kingdom' })

    // options.push({ key: 'nl', name: 'Niederlande' })
    // options.push({ key: 'fr', name: 'Frankreich' })
    // options.push({ key: 'be', name: 'Belgien' })
  }

  const isEuro =
    constraintCountry === undefined ||
    ['gb', 'dk', 'ch'].indexOf(constraintCountry) === -1

  const result = options.filter(country => {
    if (constraintCountry === undefined) {
      return true
    }

    if (isEuro) {
      return ['gb', 'dk', 'ch'].indexOf(country.key) === -1
    }

    return country.key == constraintCountry
  })

  return result
}

// Fallback to a valid country if given country is invalid
export const getValidCountry = (
  country: string,
  constraintCountry?: string,
) => {
  const validCountrys: string[] = []
  const options = getCountryOptions(constraintCountry)
  options.map((option: SelectButtonOption) => {
    validCountrys.push(option.key)
  })

  if (!country) {
    country = env.getCountry().toLowerCase()
  }

  if (validCountrys.indexOf(country) === -1) {
    country = options[0].key
  }

  return country
}

export const getCurrencyIso = (country: string) => {
  let result = 'EUR'

  if (country == 'ch') {
    result = 'CHF'
  }

  if (country == 'gb') {
    result = 'GBP'
  }

  if (country == 'dk') {
    result = 'DKK'
  }

  return result
}

export const getCurrency = (country: string) => {
  let result = '&euro;'

  if (country == 'ch') {
    result = 'CHF'
  }

  if (country == 'gb') {
    result = '£'
  }

  if (country == 'dk') {
    result = 'DKK'
  }

  return result
}

export const getNaviLanguage = () => {
  const cmsLanguage = env.getCmsLanguage()
  const cartCountry = getCartCountry()

  if (cartCountry == 'ch') {
    return cartCountry
  }

  return cmsLanguage
}

export const getPriceCountry = (country: string) => {
  let priceCountry = 'de'

  if (country == 'ch') {
    priceCountry = 'ch'
  } else if (country == 'gb') {
    priceCountry = 'gb'
  } else if (country == 'dk') {
    priceCountry = 'dk'
  } else if (country != 'de') {
    priceCountry = 'at'
  }

  return priceCountry
}

export const getCartCountry = () => {
  const cart = getCart()

  let result = undefined

  if (
    typeof cart.delivery_address !== 'undefined' &&
    typeof cart.delivery_address.country !== 'undefined'
  ) {
    result = cart.delivery_address.country
  } else {
    result = env.getCountry().toLowerCase()
  }

  result = getValidCountry(result)
  return result
}

export const getVatSet = (country: string) => {
  let result = 19

  useVatSetsData().map((vatSet: VatSet) => {
    if (vatSet.iso.toLowerCase() == country) {
      result = vatSet.vatSet
    }
  })

  return result
}

export const calculateVat = (sumGross: number, country: string) => {
  const vatSet = getVatSet(country)

  return (sumGross / 100) * vatSet
}
