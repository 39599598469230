import React, { ReactNode } from 'react'
import { SearchProvider } from './../context/SearchContext'
import { LinearProgress } from '@mui/material'

interface RootProviderProps {
  element: ReactNode
}

export const ClientRootProvider: () => React.FC<RootProviderProps> =
  () =>
  ({ element }) => {
    return (
      <React.Suspense fallback={<LinearProgress />}>
        <SearchProvider>{element}</SearchProvider>
      </React.Suspense>
    )
  }
